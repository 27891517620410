import request from '@/utils/request'

//一口价
const editProduct = (data)=> request('/pc/activity-bale/edit-product',data);
//限时折扣
const discountchoseproductlist = (data) => request('/pc/timelimitdiscount/discountchoseproductlist', data);
// 获取优惠券的弹窗产品列表
const activitycouponChoseproductlist = (data) => request('/pc/activitycoupon/choseproductlist', data);
// 获取满减送弹窗产品列表
const activityMarkdownGiftChoseProduct = (data) => request('/pc/ActivityMarkdownGift/ChoseProduct', data);
//获取积分管理弹窗产品列表
const pointconfigjoinproductlist = (data) => request('/pc/pointgiving/pointgivingconfigjoinproduct', data);

// 会员等级商品列表
export const memberVipRoleProduct= (data) => request('/pc/memberVipRole/productList',data);

// 支付有礼-商品列表
export const activityPayGiftProductList= (data) => request('/pc/activityPayGift/productList',data);

//助力领取-商品列表
// export const VirtualProductList= (data) => request('/pc/product/popList',data);


//助力领取
export const VirtualProductList= (data) => request('/pc/activityHelpReceive/productList',data);
//舌诊-选择商品列表
const tongueAnalysischooseProductlist = (data) => request('/pc/tongueAnalysis/chooseProduct/list', data);




export default {
	tongueAnalysischooseProductlist,
	editProduct,
	discountchoseproductlist,
	activitycouponChoseproductlist,
	activityMarkdownGiftChoseProduct,
	pointconfigjoinproductlist,
	memberVipRoleProduct,
	activityPayGiftProductList,
	VirtualProductList
}



















































































