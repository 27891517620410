<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="舌诊设置" name="1"></el-tab-pane>
        <el-tab-pane label="舌诊记录" name="2"></el-tab-pane>
        <el-tab-pane label="问卷记录" name="3"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName=='1'">
        <div class="flexRow">
          <buttonPermissions datas="AITongueExaminationSetCrowd" style="margin-right:10px">
            <el-button type="primary" plain @click="set">舌诊人群设置</el-button>
          </buttonPermissions>
          <el-button type="primary" plain @click="extend">舌诊链接</el-button>
        </div>
        <el-table :data="tablelist" v-loading="tableloading" style="margin-top:10px">
          <el-table-column prop="Name" label="体质" key="tab11"></el-table-column>
          <el-table-column prop="" label="推荐商品" key="tab12">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.RecommendProductCount>0" @click="lookshop(scope.row)">
                {{scope.row.RecommendProductCount}}
              </el-button>
              <div v-else>
                {{scope.row.RecommendProductCount}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="创建时间" key="tab13"></el-table-column>
          <el-table-column prop="" label="操作" key="tab14">
            <template slot-scope="scope">
              <buttonPermissions datas="AITongueExaminationrecommended" style="margin-right:10px">
                <el-button type="text" @click="recommended(scope.row)">推荐商品</el-button>
              </buttonPermissions>
              <buttonPermissions datas="AITongueExaminationscheme" style="margin-right:10px">
                <el-button type="text" @click="recuperate(scope.row)">调理方案</el-button>
              </buttonPermissions>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else-if="activeName=='2'">
        <div class="flexRow" style="flex-wrap:wrap">
          <div class="flexRow" style="margin:0px 10px 10px 0px">
            <div class="label">关键字：</div>
            <el-input v-model="keywords" placeholder="微信昵称、姓名、手机号" style="width:220px"></el-input>
          </div>
          <div class="flexRow" style="margin:0px 10px 10px 0px">
            <div class="label">体质结果：</div>
            <el-select v-model="result">
              <el-option :value="null" label="全部"></el-option>
              <el-option v-for="v in resultlist" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flexRow" style="margin:0px 10px 10px 0px">
            <div class="label">测试时间：</div>
            <el-date-picker
              v-model="time"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
          <el-button type="primary" style="margin:0px 10px 10px 0px" @click="currentPage=1,gettablelist2()">查询</el-button>
        </div>
        <el-table :data="tablelist2" v-loading="tableloading">
          <el-table-column prop="" label="客户" key="tab21" min-width="100">
            <template slot-scope="scope">
              <div class="flexRow">
                <img :src="scope.row.WxHeadUrl||defaultHeader" style="width:50px;height:50px;border-radius:3px;margin-right:5px" alt="">
                <div style="width:0;flex:1">
                  <div class="ellipsis">{{scope.row.WxNickname}}
                    <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                  </div>
                  <div>
                    {{scope.row.Phone}}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="舌诊切片" key="tab22">
            <template slot-scope="scope">
              <el-image :src="scope.row.ImgUrlComplete" style="width:50px;height:50px" :preview-src-list="[scope.row.ImgUrlComplete]" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="ConstitutionValue" label="体质结果" key="tab23"></el-table-column>
          <el-table-column prop="TongueColorValue" label="舌色" key="tab24"></el-table-column>
          <el-table-column prop="" label="舌形" key="tab25">
            <template slot-scope="scope">
              <div v-if="scope.row.TongueShapeValue&&scope.row.TongueShapeValue.length">
                {{scope.row.TongueShapeValue.join(';')}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CoatingColorValue" label="苔色" key="tab26"></el-table-column>
          <el-table-column prop="CoatingTextureValue" label="苔质" key="tab27">
            <template slot-scope="scope">
              <div v-if="scope.row.CoatingTextureValue&&scope.row.CoatingTextureValue.length">
                {{scope.row.CoatingTextureValue.join(";")}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="测试时间" key="tab28"></el-table-column>
          <el-table-column prop="" label="操作" key="tab29" width="300">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="details(scope.row,1)">体质调理</el-button>
                <el-button type="text" @click="details(scope.row,2)">可能症型</el-button>
                <el-button type="text" @click="conditioningprogram(scope.row)">调理方案</el-button>
                <el-button type="text" @click="surveydata(scope.row)">问卷数据</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <div class="flexRow" style="flex-wrap:wrap">
          <div class="flexRow" style="margin:0px 10px 10px 0px">
            <div class="label">关键字：</div>
            <el-select v-model="keyType" style="margin-right:10px;width:160px">
              <!-- <el-option :value="null" label="全部"></el-option> -->
              <el-option v-for="v in keyTypelist" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <el-input v-model="keywords2" placeholder="微信昵称、姓名、手机号" style="width:220px"></el-input>
          </div>
          <div class="flexRow" style="margin:0px 10px 10px 0px">
            <div class="label">提交时间：</div>
            <el-date-picker
              v-model="time2"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
          <el-button type="primary" style="margin:0px 10px 10px 0px" @click="currentPage=1,gettablelist3()">查询</el-button>
        </div>
        <el-table :data="tablelist3" v-loading="tableloading">
          <el-table-column prop="" label="问卷收集代理" key="tab31">
            <template slot-scope="scope">
              <div>
                <div class="ellipsis">{{scope.row.FromWxNickname}}
                  <span v-if="scope.row.FromName">({{scope.row.FromName}})</span>
                </div>
                <div>
                  {{scope.row.FromPhone}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="问卷提交客户" key="tab32">
            <template slot-scope="scope">
              <div>
                <div class="ellipsis">{{scope.row.WxNickname}}
                  <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                </div>
                <div>
                  {{scope.row.Phone}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ConstitutionValue" label="体质结果" key="tab33"></el-table-column>
          <el-table-column prop="AddTime" label="问卷提交时间" key="tab34"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="todetail(scope.row)">舌诊详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top:10px;text-align:right" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
      <select-produce api="tongueAnalysischooseProductlist" :produceData="{}" :params="params"
      :selectedData="shoplist" @getSelectList="getSelectList" :visible.sync="selectProDialog"
      v-if="selectProDialog"></select-produce>
    </el-dialog>

    <el-dialog :visible.sync="setShow" title="舌诊人群设置" width="500px" custom-class="dialog-body-paddingTop-10" top="40vh">
      <div style="font-size:14px;color:#999999FF">设置可以进行AI舌诊的人群</div>
      <div class="flexRow" style="margin-top:10px">
        <div>舌诊人群：</div>
        <el-radio-group v-model="setcrowd">
          <el-radio :label="0">全部客户</el-radio>
          <el-radio :label="1">仅推手</el-radio>
        </el-radio-group>
      </div>
      <div slot="footer">
        <el-button @click="setShow=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="shopShow" :title="`【${rowmsg.Name}】体质推荐以下商品`" custom-class="dialog-body-paddingTop-10" @close="shoplist=[]">
      <!-- <el-table :data="shoplist" v-loading="shoploading" max-height="500">
        <el-table-column prop="" label="商品" min-width="200">
          <template slot-scope="scope">
            <div class="flexRow">
              <img :src="scope.row.ImgUrlComplete" style="width:60px;height:60px;border-radius:3px;margin-right:5px" alt="">
              <div class="ellipsis-two">
                {{scope.row.Name}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="cancel(scope.row)">取消推荐</el-button>
          </template>
        </el-table-column>
      </el-table> -->
      <div class="table">
        <div class="header">
          <div class="col">商品</div>
          <div class="col" style="min-width:100px">操作</div>
        </div>
        <div class="main" v-infinite-scroll="getShop5">
          <div class="row" v-for="(v,i) in shoplist" :key="i">
            <div class="col">
              <div class="flexRow">
                <img :src="v.ImgUrlComplete" style="width:60px;height:60px;border-radius:3px;margin-right:5px" alt="">
                <div class="ellipsis-two">
                  {{v.Name}}
                </div>
              </div>
            </div>
            <div class="col" style="min-width:100px">
              <el-button type="text" @click="cancel(v)">取消推荐</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="detailShow" :title="type==1?'体质调理':'可能症型'" custom-class="dialog-body-paddingTop-10">
      <div v-if="type==1" class="max-height">
        <div class="flex-start" v-if="detailsmsg.TiaoLi.tizhi_name">
          <div class="label">体质结果：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.tizhi_name}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.changjianbiaoxian">
          <div class="label">常见表现：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.changjianbiaoxian}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.jingshentiaoyang">
          <div class="label">精神调养：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.jingshentiaoyang}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.fabingqingxiang">
          <div class="label">发病倾向：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.fabingqingxiang}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.yuletiaoshe">
          <div class="label">娱乐调摄：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.yuletiaoshe}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.sijiyangsheng">
          <div class="label">四季养生：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.sijiyangsheng}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.tiyuduanlian">
          <div class="label">体育锻炼：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.tiyuduanlian}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.qijutiaoshe">
          <div class="label">起居调摄：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.qijutiaoshe}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.yinyuetiaoli">
          <div class="label">音乐调理：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.yinyuetiaoli}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.jingluobaojian">
          <div class="label">经络保健：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.jingluobaojian}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.yongyaijinji">
          <div class="label">用药禁忌：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.yongyaijinji}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.yaowuyangsheng">
          <div class="label">药物养生：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.yaowuyangsheng}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.shiliaolist&&detailsmsg.TiaoLi.shiliaolist.length">
          <div class="label">食疗：</div>
          <div class="default-font" style="white-space:pre-wrap">{{detailsmsg.TiaoLi.shiliaolist.join('\n')}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.yichi">
          <div class="label">宜吃：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.yichi}}</div>
        </div>
        <div class="flex-start" v-if="detailsmsg.TiaoLi.shenchi">
          <div class="label">慎吃：</div>
          <div class="default-font">{{detailsmsg.TiaoLi.shenchi}}</div>
        </div>
      </div>
      <div v-else class="max-height">
        <div class="symptom" v-for="(v,i) in detailsmsg.Zhengxings" :key="i">
          <div class="title">{{v.zhengxing_name}}</div>
          <div class="explain">{{v.jieshi}}</div>
          <div class="content" v-if="v.shiyishiwu">
            <span style="color:#303133">适宜食物：</span>
            {{v.shiyishiwu}}
          </div>
          <div class="content" v-if="v.jinjishiwu">
            <span style="color:#303133">禁忌食物：</span>
            {{v.jinjishiwu}}
          </div>
          <div class="content" v-if="v.shiyiyundong">
            <span style="color:#303133">适宜运动：</span>
            {{v.shiyiyundong}}
          </div>
          <div class="content" v-if="v.jinjiyundong">
            <span style="color:#303133">禁忌运动：</span>
            {{v.jinjiyundong}}
          </div>
          <div class="content" v-if="v.shiyiyaoshan">
            <span style="color:#303133">适宜药膳：</span>
            {{v.shiyiyaoshan}}
          </div>
          <div class="content" v-if="v.shiyiyinyue">
            <span style="color:#303133">适宜音乐：</span>
            {{v.shiyiyinyue}}
          </div>
        </div>
      </div>
    </el-dialog>
    
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false"></batch-cods>

    <el-dialog :visible.sync="nurseShow" :title="`【${rowmsg.Name}】体质调理方案设置`" @close="nurseImg=''" v-loading="nurseloading" width="500px">
      <div style="display:flex">
        <div style="margin-left:10px">
          调理方案：
        </div>
        <div>
          <div v-if="nurseImg">
            <div class="imgbox">
              <img class="noumenon" :src="imgUrl+nurseImg" alt="" >
              <div class="maskbox">
                <div class="mask">
                  <i class="el-icon-zoom-in" @click="handlePictureCardPreview"></i>
                  <i class="el-icon-delete" @click="handleRemove"></i>
                </div>
              </div>
            </div>
          </div>
          <el-upload v-else :action="action" accept=".png,.jpg" :limit="1" list-type="picture-card" :before-upload="beforeUpload"
           :on-success="onSuccess" :show-file-list="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div style="color:#999999;font-size:14px;margin-top:5px">图片大小1M以内</div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="nurseShow=false,nurseImg=''">取消</el-button>
        <el-button type="primary" @click="saveImg">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible" title="调理方案">
      <div style="width:100%;max-height:600px;overflow-y:auto">
        <img width="100%" :src="imgUrl+nurseImg" alt="" />
      </div>
    </el-dialog>

    <el-dialog :visible.sync="imgShow" title="">
      <div style="width:100%;max-height:600px;overflow-y:auto">
        <el-carousel height="500px">
          <el-carousel-item v-for="(item,i) in ImgUrls" :key="i">
            <div class="flex flex-align-center flex-justify-center" style="max-width:100%;max-height:500px">
              <img :src="imgUrl+item" style="max-width:100%;max-height:500px" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="surveyShow" title="问卷数据" width="600px" custom-class="dialog-body-paddingTop-10">
      <div class="max-height">
        <div class="flex font-14" style="line-height:1.5">
          <div class="keyLabel">问卷收集代理：</div>
          <div>
            <div class="ellipsis" style="width:400px">{{surveymsg.FromWxNickname}} <span v-if="surveymsg.FromName">({{surveymsg.FromName}})</span> </div>
            <div>{{surveymsg.FromPhone}}</div>
          </div>
        </div>
        <div class="flex font-14 margin-top-20" style="line-height:1.5">
          <div class="keyLabel">问卷提交客户：</div>
          <div>
            <div class="ellipsis" style="width:400px">{{surveymsg.WxNickname}} <span v-if="surveymsg.Name">({{surveymsg.Name}})</span> </div>
            <div>{{surveymsg.Phone}}</div>
          </div>
        </div>
        <div class="flex font-14 margin-top-20" style="line-height:1.5">
          <div class="keyLabel">问卷提交时间：</div>
          <div>
            {{surveymsg.AddTime}}
          </div>
        </div>
        <div class="list margin-top-20">
          <div class="row" v-for="(v,i) in surveymsg.QuestionnaireTopicList" :key="i">
            <div class="flex">
              <div class="keyLabel">{{i+1}}.</div>
              <div>
                <div class="keyLabel" style="white-space: normal;">{{v.TopicDescription}}</div>
                <div>
                  <div v-if="v.TopicType==4">
                    <el-button type="text" @click="lookimg(v.result)">点击查看图片</el-button>
                  </div>
                  <div v-else>
                    {{v.OptionDescription}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import selectProduce from './components/selectProduce'
import batchCods from "@/components/bathCodes"
import{
  tongueAnalysisinit,
  tongueAnalysisphysicalFitnesslist,
  recommendProductedit,
  tongueAnalysiscrowdTypeedit,
  tongueAnalysisrecommendProductlist,
  tongueAnalysistongueAnalysisRecord,
  tongueAnalysistongueAnalysisInfo,
  physicalFitnessphysicalFitnessImgUrlSave,
  tongueAnalysistongueAnalysisRecordQuestionData,
  tongueAnalysistongueAnalysisQuestionData
} from "@/api/sv3.0.0"
import config from "@/config"
import {
  nextDay
} from "@/utils"
export default {
  components: {
    buttonPermissions,
    selectProduce,
    batchCods
  },
  data () {
    return {
      activeName:'1',
      tablelist:[],
      tableloading:false,
      setcrowd:0,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
      selectProDialog:false,
      produceData:[],
      params:{},
      setShow:false,
      shopShow:false,
      shoplist:[],
      shoploading:false,

      keywords:'',
      result:null,
      resultlist:[
        {value:1,label:'血瘀'},
        {value:2,label:'湿热'},
        {value:3,label:'特禀'},
        {value:4,label:'阳虚'},
        {value:5,label:'阴虚'},
        {value:6,label:'痰湿'},
        {value:7,label:'平和'},
        {value:8,label:'气郁'},
        {value:9,label:'气虚'},
      ],
      time:[],
      tablelist2:[],
      shopindex:0,
      defaultHeader:config.DEFAULT_HEADER,
      detailShow:false,
      type:1,
      detailsmsg:{
        TiaoLi:{},
        Zhengxings:[]
      },
			weiCodeData: {},
			weiCodeShow: false,
      nurseShow:false,
      nurseloading:false,
      action:config.UPLOAD_IMG,
      imgUrl:config.IMG_BASE,
      nurseImg:'',
      dialogVisible:false,
      surveyShow:false,
      surveymsg:{},
      imgShow:false,

      keywords2:'',
      keyType:1,
      keyTypelist:[
        {value:0,label:'问卷收集代理'},
        {value:1,label:'问卷提交客户'},
      ],
      time2:[],
      tablelist3:[],
      MemberQuestionnaireId:'',
      ImgUrls:[],
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    todetail(row){
      this.activeName = '2'
      this.MemberQuestionnaireId = row.Id
      this.handleClick({name:'2'})
    },
    lookimg(v){
      if(!v.ImgUrls||!v.ImgUrls.length){
        this.$message.error('客户未上传图片')
        return
      }
      this.ImgUrls = v.ImgUrls
      this.imgShow = true
    },
    async surveydata(row){
      this.rowmsg = row
      try{
        this.table = true
        let res = await tongueAnalysistongueAnalysisRecordQuestionData({
          Id:row.Id,
        })
        if(res.IsSuccess){
          if(!Object.keys(res.Result).length){
            this.$message.error('本次舌诊未提交问卷数据')
          }else{
            this.surveyShow = true
            let msg = res.Result
            msg.QuestionnaireTopicList = msg.QuestionnaireTopicList.map(v=>{
              v.OptionDescription = ''
              if(v.TopicType==1){
                v.resultlist = msg.MemberQuestionnaireDetailList.filter(x=>x.QuestionnaireTopicId==v.Id)||[]
                v.OptionDescription = v.resultlist.map(x=>x.IsOther?x.Text:x.OptionDescription).join(',')
              }else{
                v.result = msg.MemberQuestionnaireDetailList.find(x=>x.QuestionnaireTopicId==v.Id)||{
                  OptionDescription:'',
                  Text:'',
                  ImgUrls:[]
                }
                if([2,3].includes(v.TopicType)){
                  v.OptionDescription = v.result.Score
                }else if(v.TopicType==0){
                  v.OptionDescription = v.result.IsOther?v.result.Text:v.result.OptionDescription
                }else if(v.TopicType==6){
                  v.OptionDescription = v.result.OptionDescription
                }else if([5,7].includes(v.TopicType)){
                  v.OptionDescription = v.result.Text
                }else{
                  v.OptionDescription = v.result.ImgUrls
                // console.log(v)
                }
              }
              return v
            })
            this.surveymsg = msg
            console.log(msg)
          }
        }
      }finally{
        this.tableloading = false
      }
    },
    conditioningprogram(row){
      this.nurseImg = row.PhysicalFitnessImgUrl
      this.dialogVisible = true
    },
    async saveImg(){
      try{
        this.nurseloading = true
        let res = await physicalFitnessphysicalFitnessImgUrlSave({
          Id:this.rowmsg.Id,
          PhysicalFitnessImgUrl:this.nurseImg
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.nurseShow = false
          this.gettablelist()
        }
      }finally{
        this.nurseloading = false
      }
    },
    beforeUpload(file) {
      const size = file.size / 1024 / 1024 < 1;
      const testtype = ['image/png','image/jpeg', 'image/jpg']
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('图片大小请控制在1M及以内');
        return false
      }
    },
    onSuccess(file, num) {
      this.nurseImg = file[0]
    },
    handleRemove() {
      this.nurseImg = ''
    },
    handlePictureCardPreview() {
      this.dialogImageUrl = this.nurseImg;
      this.dialogVisible = true;
    },
    recuperate(row){
      this.nurseImg = row.PhysicalFitnessImgUrl
      this.rowmsg = row
      this.nurseShow = true
    },
    extend(){
      this.weiCodeShow = true
      this.weiCodeData = {
        Page: 'pageC/pages/tongueDiagnosis/home',
				Scene: `1`
      }
    },
    details(row,type){
      this.rowmsg = row
      this.type = type
      this.detailShow = true
      this.getdetails()
    },
    async getdetails(){
      try{
        let res = await tongueAnalysistongueAnalysisInfo({
          Id:this.rowmsg.Id
        })
        if(res.IsSuccess){
          this.detailsmsg = res.Result
          // console.log(this.detailsmsg)
        }
      }finally{
        //
      }
    },
    async cancel(row){
      try{
        this.shoploading = true
        let res = await recommendProductedit({
          TongueAnalysisPhysicalFitnessId:this.rowmsg.Id,
          ProductIdList:this.shoplist.filter(v=>{
            return v.Id!=row.Id
          }).map(v=>v.Id)
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.getshoplist()
          this.gettablelist()
        }
      }finally{
        this.shoploading = false
      }
    },
    lookshop(row){
      this.rowmsg = row
      this.shopShow = true
      this.shopindex = 0
      this.shoplist = []
      this.getShop5()
    },
    async getShop5(){
      try{
        this.shopindex++
        this.shoploading = true
        let res = await tongueAnalysisrecommendProductlist({
          TongueAnalysisPhysicalFitnessId:this.rowmsg.Id,
          Skip:(this.shopindex-1)*5,
          Take:5
        })
        if(res.IsSuccess){
          this.shoplist = [...this.shoplist,...res.Result.Results]
        }
      }finally{
        this.shoploading = false
      }
    },
    async getshoplist(){
      try{
        this.shoploading = true
        let res = await tongueAnalysisrecommendProductlist({
          TongueAnalysisPhysicalFitnessId:this.rowmsg.Id,
          Skip:0,
          Take:99999
        })
        if(res.IsSuccess){
          this.shoplist = res.Result.Results
        }
      }finally{
        this.shoploading = false
      }
    },
    async save(){
      try{
        let res = await tongueAnalysiscrowdTypeedit({
          TongueAnalysisCrowdType:this.setcrowd
        })
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.setShow = false
        }
      }finally{
        //
      }
    },
    set(){
      this.init()
      this.setShow = true
    },
    async getSelectList(list){
      if(!list||!list.length){
        this.$message.error('请选择推荐商品')
        return
      }
      try{
        let res = await recommendProductedit({
          TongueAnalysisPhysicalFitnessId:this.rowmsg.Id,
          ProductIdList:list.map(v=>v.Id)
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.selectProDialog = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    async recommended(row){
      this.rowmsg = row
      this.params = {
        TongueAnalysisPhysicalFitnessId:row.Id
      }
      await this.getshoplist()
      this.selectProDialog = true
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      if(this.activeName==1){
        this.gettablelist()
      }else if(this.activeName==2){
        this.gettablelist2()
      }else{
        this.gettablelist3()
      }
    },
    handleCurrentChange(e){
      this.currentPage = e
      if(this.activeName==1){
        this.gettablelist()
      }else if(this.activeName==2){
        this.gettablelist2()
      }else{
        this.gettablelist3()
      }
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await tongueAnalysisphysicalFitnesslist({
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    async gettablelist2(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          Constitution:this.result,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
          MemberQuestionnaireId:this.MemberQuestionnaireId
        }
        if(this.time&&this.time.length){
          data.StartTime = this.time[0]
          data.EndTime = nextDay(this.time[1],1)
        }
        let res = await tongueAnalysistongueAnalysisRecord(data)
        if(res.IsSuccess){
          this.tablelist2 = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.MemberQuestionnaireId = ''
        this.tableloading = false
      }
    },
    async gettablelist3(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords2,
          KeywordsType:this.keyType,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        if(this.time2&&this.time2.length){
          data.StartTime = this.time2[0]
          data.EndTime = nextDay(this.time2[1],1)
        }
        let res = await tongueAnalysistongueAnalysisQuestionData(data)
        if(res.IsSuccess){
          this.tablelist3 = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleClick(e){
      this.currentPage = 1
      this.pagesize = 20
      if(e.name==1){
        this.gettablelist()
      }else if(e.name==2){
        this.gettablelist2()
      }else{
        this.gettablelist3()
      }
    },
    async init(){
      try{
        let res = await tongueAnalysisinit()
        if(res.IsSuccess){
          // console.log(res.Result)
          this.setcrowd = res.Result.TongueAnalysisCrowdType
        }
      }finally{
        //
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list{
  padding:10px 0px;
  .row{
    border: 1px solid #EBEEF5;
    background: #FFFFFF;
    padding:12px 16px;
    border-top: none;
    &:first-child{
      border-top: 1px solid #EBEEF5 ;
    }
  }
}
.flexRow{
  display: flex;
  align-items: center;
  .label{
    font-size: 14px;
    font-weight: bold;
    color: #606266;
    white-space: nowrap;
    flex-shrink: 0;
  }
}
.max-height{
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
  .flex-start{
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    .label{
      white-space: nowrap;
      flex-shrink: 0;
      color: #606266;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
      width: 100px;
      text-align: right;
    }
    .default-font{
      font-weight: 400;
      color: #606266;
      line-height: 20px;
      font-size: 14px;
    }
  }
  .symptom{
    margin-top: 10px;
    background: #FBFBFB;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #EEEEEE;
    padding: 16px;
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #606266;
      line-height: 22px;
    }
    .explain{
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
    }
    .content{
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #999999FF;
      line-height: 20px;
    }
  }
}
.table{
  .header{
    background: rgb(248, 248, 249);
    padding: 10px 0px;
    font-weight: bold;
    color: #909399;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EBEEF5;
    .col{
      padding: 10px;
    // border: 1px solid black;
    }
  }
  .main{
    color: #909399;
    max-height: 400px;
    overflow-y: auto;
    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EBEEF5;
      .col{
        background: white;
        color: #606266;
        // border: 1px solid black;
        // display: flex;
        // align-items: center;
        padding: 10px;
      }
    }
  }

}
.imgbox{
  width: 148px;
  height: 148px;
  border-radius: 6px;
  // border:1px solid black;
  position: relative;
  .noumenon{
    width: 100%;
    height: 100%;
  }
  &:hover .maskbox{
    display:block
  }
  .maskbox{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .mask{
      border-radius: 6px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background: rgba(0, 0, 0, .3);
      i{
        z-index: 99;
        cursor: pointer;
        font-size: 20px;
        color: white;
      }
    }
  }
}
</style>